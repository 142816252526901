<template>
<div v-if="tabs !== null" class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
        <li v-for="(tab, index) in tabs" :key="index + 't'">
          <div v-if="!tab.disabled" class="mr-2 cursor-pointer" @click="tabsSet(index)">
            <span href="#" :class="`${ tab.active || tab.complete ? 'text-primary  border-primary' : 'border-transparent'} border-b-2 inline-block px-4 py-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500`" aria-current="page">{{tab.name}}  <span v-if="tab.complete"><i class="fa-solid fa-check text-primary"></i></span></span>
          </div> 
          
          <div v-else class="mr-2 cursor-pointer">
            <span href="#" class="text-gray3  border-gray3 border-transparent border-b-1 inline-block px-4 py-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500`" aria-current="page">{{tab.name}}</span>
          </div> 

        </li>
    </ul>
</div>
</template>
<script>
export default {
  props: ['tabsList'],
  data () {
    return {
        tabs: null
    }
  },
  mounted () {
    console.log('tabsList', this.tabsList)
    this.tabs = this.tabsList
  },
  watch: {
  },
  methods: {
    tabsSet (index) {
        this.$root.$emit('setNewTabActive', index)
    },
  }
}
</script>
<style scoped>
</style>
